
import { BASE_URL } from "./config";
import axios from 'axios';

const token = localStorage.getItem("authToken");
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${token}`,
  },
});
axiosInstance.defaults.withCredentials = true;
export default axiosInstance;
