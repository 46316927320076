import { configureStore } from "@reduxjs/toolkit";
import campaignReducer from "./store/campaignSlice/campaignSlice";
import domainReducer from "./store/domainSlice/domainSlice";

const store = configureStore({
  reducer: {
    campaigns: campaignReducer,
    domain: domainReducer,
  },
});

export default store;
