// App.js

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import "@fontsource/poppins";
import "./App.css";
import store from "./store"; 
import Loader from "./Components/Buttons/loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Lazy loaded components
const SignInPage = lazy(() => import("./Pages/SignIn/SignIn"));
const LandingPage = lazy(() => import("./Pages/Main_landing_page/Landing_Page"));
const DashboardPage = lazy(() => import("./Pages/Dashboard/Dashboard"));
const Signup = lazy(() => import("./Pages/SignIn/Signup"));
const CreateNewPassword = lazy(() => import("./Components/Forgot-Password/setNewPassword"));
const ProtectedRoute = lazy(() => import("./Components/protected/Protected"));
const CreateCampaign = lazy(() => import("./Pages/campaign/createCampaign"));
const HoverEffect = lazy(() => import("./Components/campaignDetails/campaignAnalytics"));
const Subsequence = lazy(() => import("./Components/campaignDetails/addSubsequence"));
const DomainAccounts = lazy(() => import("./Pages/Dashboard-pages/Accounts/domainaccounts"));
const BulkDomain = lazy(() => import("./Pages/Dashboard-pages/Accounts/bulkDomain"));
const DomainCheckout = lazy(() => import("./Pages/Dashboard-pages/Accounts/DomainCheckout"));

function App() {
  return (
    <div>
      {/* Single ToastContainer at the root of your app */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Provider store={store}>
        <Router>
          <Suspense fallback={<Loader />}> {/* Display loader while components are lazy-loaded */}
            <Routes>
               <Route
                  path="/"
                  element={<Navigate to="/login" replace />}
                />
              // <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<SignInPage />} />
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/resetPassword/:resetToken"
                element={<CreateNewPassword />}
              />
              <Route
                path="/dashboard/*"
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/campaigns/create"
                element={
                  <ProtectedRoute>
                    <CreateCampaign />
                  </ProtectedRoute>
                }
              />
              <Route path="/hover" element={<HoverEffect />} />
              <Route path="/domainaccounts" element={<DomainAccounts />} />
              <Route path="/bulkdomain" element={<BulkDomain />} />
              <Route path="/checkout" element={<DomainCheckout />} />
            </Routes>
          </Suspense>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
